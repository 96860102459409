import PricingElement from './PricingElement';
import { homePricingContent } from './homePricingContent';
import SectionTitle from 'components/SectionTitle';
import { Trans } from 'next-i18next';
import Link from 'next/link';

const ComputingPricing = () => {
  return (
    <div className="pricing" id="pricing">
      <SectionTitle
        header="home.pricing.header"
        description="home.pricing.description"
      />
      <div className="pricing-examples">
        {homePricingContent.map((instance, index) => (
          <PricingElement key={index} instance={instance} />
        ))}
      </div>
      <div className="pricing-support">
        <Trans i18nKey="home.pricing.support">
          Need a different GPU? Just reach out to
          <Link
            className="pricing-examples__element-message-link link-type-underline"
            href="/support"
          >
            our support team
          </Link>
          !
        </Trans>
      </div>
    </div>
  );
};

export default ComputingPricing;
